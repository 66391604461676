<template>
   <sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Add New Centers
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
        <a-row :gutter="25"></a-row>
        <a-row :gutter="25">
          <a-col>
            <BasicFormWrapper>
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="30">
            <a-col>
              <a-form-item name="cname">
                <a-input
                  v-model:value="formState.cname"
                  placeholder="Input Center Name"
                />
              </a-form-item>
            </a-col>
            <a-col>
            <a-form-item name="ccode">
                <a-input
                  v-model:value="formState.ccode"
                  placeholder="Input Center Code"
                />
            </a-form-item>    
            </a-col>
              <a-col>
                <a-button 
                htmlType="submit"
                class="btn-add_new"
                size="default"
                type="primary"
                key="submit"
              >
               +Add New Centers
              </a-button>
              </a-col>
              </a-row>
            </a-form>
          </BasicFormWrapper>
          </a-col>
          </a-row>
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
           Existing Centers List
    </sdHeading>
      <template v-if="selectedoption == 'Active'">
       
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :headers="table_headers"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'CentersForm',
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var linedata = [];
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    var supplierdrop = ref();
    var contact_id = ref(null);
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    onMounted(() => {
    getdata();
    }); 
    const formState = reactive({
      ccode: "",
      cname: "",
      layout: "vertical",
    });
    state.LeadsCenters.data = [];
    const handleOk = (values) => {
      addCenter();
    };
const getdata = () => {
      loading.value = true;
      dispatch("getCentersData").then(
        (response) => {
          //console.log(response.data);
          if (response.status == 200) {
            state.LeadsCenters.data = response.data;
            //loading.value = false;
          } else {
            state.LeadsCenters.data = [];
            //loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          //console.log(error);
          //loading.value = false;
          state.LeadsCenters.data = [];
        }
      );
    };
    const addCenter = () => {
        let api_data = [];
        api_data['ccode'] = formState.ccode;
        api_data['cname'] = formState.cname;
        loading.value = true;
        dispatch("addCenters",api_data).then(
            (response) => {
                if (response.status == 200) {
                    location.reload();
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Center Added.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Adding Center",
            });
          } 
          
        );
    }
    
    const TableColumns = [
    {
        title: "",
        dataIndex: "",
        key: "branch_code",
      },
    {
        title: "Id ",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Center Name",
        dataIndex: "branch_name",
        key: "branch_name",
        sorter: (a, b) => a.branch_name.length - b.branch_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Center Code",
        dataIndex: "branch_code",
        key: "branch_code",
        sorter: (a, b) => a.branch_code.length - b.branch_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "",
        dataIndex: "",
        key: "branch_code",
      },
      
    ];
   
     let TableData = computed(() =>
        state.LeadsCenters.data.map((unreconcile) => {
          const {
            id,
            branch_name,
            branch_code,
            } = unreconcile;
          return {
            key: id,
            id: id,
            branch_name: branch_name,
            branch_code:branch_code,
            } 
            
        }
        )
     
    );
    


    return {
      loading,
      TableColumns,
      TableData,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      formState,
      getdata,
      supplierdrop,
      orgdata
    };
  },
});
</script> 
<style scoped>

.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
</style>