<template>
<sdPageHeader>
<template #title>
 <div headless>
  
  <router-link to="/nxtsprint/7">
    <ProductCard
    class="list-view"
    v-if="dispData"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="user-check" size="34" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;"
    >
    {{count7}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    New Leads
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/sprint/1">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="user-check" size="34" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;"
    >
    {{count1}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style="left: -10px !important; margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Appointments
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/sprint/2">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px', display:'none'}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="phone" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count2}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
   Schedule calls
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/sprint/3">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="repeat" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count3}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
   Follow up
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/nxtsprint/4">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="clock" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count4}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 21px; font-size: 20px; color:#666666;"
    >
   In progress
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/nxtsprint/5">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="user-plus" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count5}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Assigned
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/nxtsprint/11">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="send" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count11}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Mail Sent
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    </div>
    </template>
    </sdPageHeader>
    <sdPageHeader style="padding-bottom: 00px !important;">
    <template #title>
    <div headless>
    <router-link to="/nxtsprint/6" id="content-prospecting">
    <ProductCard
    class="list-view"
    v-if="dispAdmin"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="file-text" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count6}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Prospecting
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/nxtsprint/8" >
    <ProductCard
    class="list-view"
    v-if="dispAdmin"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="file-text" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count8}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Proposal
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/nxtsprint/9">
    <ProductCard
    class="list-view"
    v-if="dispAdmin"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="file-text" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count9}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Negotiation
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/clksprint/10">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="mail" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count10}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Clicks
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/opnsprint/12">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="mail" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count12}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    MailChimp
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    <router-link to="/clksprint/13">
    <ProductCard
    class="list-view"
    v-if="dispSales"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="mail" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count13}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    Open
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    </router-link>
    </div>
    </template>
    </sdPageHeader>  
<!-- Only task count -->
<sdPageHeader style="padding-bottom: 40px !important;">
    <template #title>
    <div headless>
    <ProductCard
    class="list-view"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="phone-forwarded" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count14}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 10px; font-size: 18px; color:#666666;"
    >
    Call's Completed
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    <ProductCard
    class="list-view"
    :style="{ width: '15%', marginBottom: '20px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;-webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);">
    <a-row :gutter="24">
    <a-col :md="2" :xs="2"></a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
      <sdFeatherIcons type="users" size="32" style="color:#7fd9f0"/>
    </div>
    </a-col>
    <a-col :md="6" :xs="6">
    <div class="product-single-description">
    <sdHeading
    class="product-single-title"
    style="margin: 0px; font-size: 24px; font-weight: 800; color:#666666;">
    {{count15}}
    </sdHeading>
    </div>
    </a-col>
    </a-row>
    <a-row :gutter="24">
    <a-col :md="24" :xs="24">
    <sdHeading
    class="product-single-title"
    style=" margin: 0px 0px 0px 20px; font-size: 18px; color:#666666;"
    >
    DM's Spoken
    </sdHeading>
    </a-col>
    </a-row>
    </div>
    </ProductCard>
    
    </div>
    </template>
    </sdPageHeader> 

    <Main>
    <div style="padding: 30px 30px 30px 30px !important; background-color: #fff;  box-shadow: 0 0 10px #ddd;">
    <template v-if="selectedoption == 'Active'">


    <a-row>
    <a-col :md="18">
     <sdHeading class="product-single-title" as="h3" style="line-height: 50px; ">To Do List
    </sdHeading>
    </a-col>
    <a-col :md="6">
     <sdHeading class="product-single-title" as="h3" style="line-height: 50px">Search Leads
    </sdHeading>
    </a-col>
    </a-row>
    <a-row :gutter="24">
          <a-col :md="16">
            <div class="setting-card-title">
              
            <a-form
              name="contact"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row>
            <a-col>
              <a-form-item name="date">
                <a-range-picker
                  style="margin-top: -25px !important; padding-left: 25px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                 />
              </a-form-item>
            </a-col>
            <a-col>
                <a-button 
                style="margin: 0 20% !important"
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
            >
                Submit
              </a-button>
              </a-col>
              </a-row>
            </a-form>
           </div>
          
          </a-col>
          <a-col :md="8">
            <div class="setting-card-title">
              
            <a-form
              name="contact"
              :model="formState"
              @submit="handleOk1"
              :layout="formState.layout"
            >
            <a-row>
            <a-col :md="12">
              <a-form-item name="term">
               <a-input
                  v-model:value="formState.term"
                  placeholder="Input E-mail"
                  style="top: -5px !important"
                />

              </a-form-item>
             </a-col>
            <a-col>
                <a-button 
                style="margin: 0 20% !important"
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
            >
                Submit
              </a-button>
              </a-col>
              </a-row>
            </a-form>
           </div>
          </a-col>
          
          </a-row>
        <a-row :gutter="24">
          <a-col :md="16">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
          <a-col :md="8">
            <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData1"
                :columns="TableColumns1"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData1.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>

          </a-col>
          </a-row>
      </template>
      
    </div>
  </Main> 
 <a-modal
  :type="primary"
  title="Password Change Alert"
  :visible="localState.dispModal"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Change Password Alert: Goto Profile page & Please change your password</h2>
          <a-button
                size="default"
                type="primary"
                
                @click="disProfile()"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="key" size="14" /> &nbsp;
             Change Password</span>
              </a-button>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <a-modal
  :type="primary"
  title="Lead Alert"
  :visible="localState.schedVisible"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Alert: Lead you are searching is Dead/Lost</h2>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <a-modal
  :type="primary"
  title="Existing Customer Alert"
  :visible="localState.wonVisible"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Alert: Lead you are searching is Already Won</h2>
        </a-col>
      </a-row>
    </div>
  </a-modal>
  <a-modal
  :type="primary"
  title="Unsubscribed Customer Alert"
  :visible="localState.unsubVisible"
  :footer="null"
  :onCancel="handleCancel"
  :width= "720"
  >
    <div class="project-modal">
      <a-row :gutter="15">
        <a-col :md="24">
          <h2>Alert: Lead you are searching is Unsubscribed</h2>
        </a-col>
      </a-row>
    </div>
  </a-modal>
</template>

<script>
import { Main, TableWrapper } from "../styled";
import { computed, ref, defineComponent, onMounted,reactive} from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { message, Notification } from 'ant-design-vue'; 
import { UserTableStyleWrapper, Action,ContactCardWrapper, ProductCard } from "./style";
import * as FontAwesomeIcon from "@fortawesome/free-solid-svg-icons";



export default defineComponent({
  name: "dashboard",

  components: {
    Main,
    TableWrapper,
    UserTableStyleWrapper,
    ProductCard,
    ContactCardWrapper,
    FontAwesomeIcon,
    Action,
    
  },
 setup() {
    let empty = ref(true);
    let loading = ref(true);
    var dispData = ref(false);
    var dispSales = ref(false);
    var dispAdmin = ref(false);
    const param = useRoute();
    const router = useRouter();
    const { state, dispatch } = useStore();
    var selectedoption = ref('Active');
    var selectrange = ref(null);
    var count1 = ref();
    var count2 = ref();
    var count3 = ref();
    var count4 = ref();
    var count5 = ref();
    var count6 = ref();
    var count7 = ref();
    var count8 = ref();
    var count9 = ref();
    var count10 = ref();
    var count11 = ref();
    var count12 = ref();
    var count13 = ref();
    var count14 = ref();
    var count15 = ref();
    const test = 5;
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    state.LeadsData.data = [];
    state.SearchData.data = [];
    onMounted(() => {
      getCount();
      getdata("Today");
      dispTab();
      dispPass();
    });
    const formState = reactive({
      date1: "",
      date2: "",
      layout: "vertical",
      term: "",
    });
    const handleOk1 = (values) => {
      searchData();
      //alert("Search Tab");
    };
    const searchData = () => {
        let api_data = [];
        api_data['criteria'] = "onlyEmail";
        api_data['condition'] = "Null";
        api_data['term'] = formState.term;
        api_data['industry'] = "Null";
        api_data['status'] = "Null";
        loading.value = true;
        dispatch("searchLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    state.SearchData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.SearchData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
    }
    const handleOk = (values) => {
    getdata("Range");  
    };
    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
    };
    const dispTab = () => {
   
     let role = JSON.parse(localStorage.getItem("usr_role"));
      
      //alert(role.role_id);
      if (role.role_id=="1" || role.role_id=="2") {
            dispData.value=true;
            dispSales.value=true;
            dispAdmin.value=true;
            
      } 
      else if(role.role_id=="3") {
        dispData.value=true;
        dispSales.value=true;
        dispAdmin.value=false;
      }
      else if (role.role_id=="4" || role.role_id=="5"){
        dispData.value=true;
        dispSales.value=false;
        dispAdmin.value=false;
      }
    };
    const disProfile = () => {
    router.push('/profile');
    };
    const localState = reactive({
      dispModal: false,
      schedVisible:false,
      wonVisible:false,
      unsubVisible:false,
      });
    const handleCancel = () => {
      localState.dispModal= false;
      localState.schedVisible=false;
      localState.wonVisible=false;
      localState.unsubVisible=false;
    };
    const showModal = () => {
      localState.schedVisible= true;
    };
    const showModal1 = () => {
      localState.wonVisible= true;
    };
    const showModal2 = () => {
      localState.unsubVisible= true;
    };
    const dispPass = () => {
   
     let role = JSON.parse(localStorage.getItem("psw_val"));
      
      //alert(role);
      if (role==false) {
      //alert(role);
            localState.dispModal=true;
      } 
      else  {
      //alert(role);
        localState.dispModal=false;
      }
      
    };
    const getdata = (value) => {
      loading.value = true;
      var range="";
      let startdate="";
      let enddate="";
      let api_data = [];
      if(value=="Range"){
      range = selectrange.value.toString().split(",");
      startdate = new Date(range[0]);
      enddate = new Date(range[1]);
      startdate = startdate.toLocaleDateString("en-CA");
      enddate = enddate.toLocaleDateString("en-CA");
      }
      else{
      var today = new Date();
      // Get tomorrow's date
      var tomorrow = new Date();
      tomorrow.setDate(today.getDate() + 1);
      // Get the day after tomorrow's date
      var dayAfterTomorrow = new Date();
      dayAfterTomorrow.setDate(today.getDate() + 2);
      startdate = today.toLocaleDateString("en-CA");
      enddate = tomorrow.toLocaleDateString("en-CA");
      }
      
      
      //alert(startdate);
      //alert(enddate);
      api_data['startdate'] = startdate;
      api_data['enddate'] = enddate;
      api_data['prop'] = value;
      dispatch("getLeadsData",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsData.data = response.data;
            loading.value = false;

          } else {
            state.LeadsData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.LeadsData.data = [];
        }
      );
    };
    const getCount = () => {

      dispatch("getDashboardCounts").then(
        (response) => {
          if (response.status == 200) {
            count1.value = response.data[0];
            count2.value = response.data[1];
            count3.value = response.data[2];
            count4.value = response.data[3];
            count5.value = response.data[4];
            count6.value = response.data[5];
            count7.value = response.data[6];
            count8.value = response.data[7];
            count9.value = response.data[8];
            count10.value = response.data[9];
            count11.value = response.data[10];
            count12.value = response.data[11];
            count13.value = response.data[12];
            count14.value = response.data[13];
            count15.value = response.data[14];
            //console.log(counts.value);
            //const test = 10;
            loading.value = false;
          } else {
            //counts.value = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          loading.value = false;
          //counts.value = [];
        }
      );
    };
    const openurl = (id) => {
      let url = '/index/';
      window.open(
          url + id +'/0',
          "_self",
      ); 
      
    };
    const TableColumns = [
     {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
       },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Task Type",
        dataIndex: "schedule_type",
        key: "schedule_type",
        sorter: (a, b) => a.schedule_type.length - b.schedule_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Date",
        dataIndex: "next_followup",
        key: "next_followup",
        sorter: (a, b) => new Date(a.next_followup) - new Date(b.next_followup),
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Time",
        dataIndex: "schedule_time",
        key: "schedule_time",
        sorter: (a, b) => a.schedule_time.length - b.schedule_time.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "User",
        dataIndex: "assign_user",
        key: "assign_user",
        sorter: (a, b) => a.assign_user.length - b.assign_user.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
  const TableColumns1 = [
     {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
       },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "User",
        dataIndex: "user_id",
        key: "user_id",
        sorter: (a, b) => a.user_id.length - b.user_id.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];  
  let TableData = computed(() =>
        state.LeadsData.data.map((unreconcile) => {
          const {
            id,
            lead_id,
            Company,
            Name,
            interest,
            user_id,
            current_status,
            schedule_type,
            schedule_date,
            schedule_time,
            assign_user,
            next_followup,
            Action,
            } = unreconcile;
          return {
            key: id,
            id: id,
            next_followup:next_followup,
            Company: (
            <div class="table-actions">
              <>
                <a style="text-align: left !important;"onClick={() => openurl(lead_id)} to="#">
                  <span>
                    {Company}
                    </span>
                </a>
              </>
            </div>
          ),
            Name:Name,
            interest: interest,
            user_id: user_id,
            current_status: current_status,
            Action: Action,
            schedule_type: schedule_type,
            schedule_date: schedule_date,
            schedule_time: schedule_time,
            assign_user:assign_user,
            } 
            
        }
        )
     
    );
let TableData1 = computed(() =>
        state.SearchData.data.map((unreconcile) => {
          const {
            id,
            Company,
            Name,
            Email_Address,
            user_id,
            current_status,
            email_unsubscribe,
            } = unreconcile;
            let companyComponent;
    if (
      current_status !== "Dead" &&
      current_status !== "Closed Lost" &&
      current_status !== "Closed Won" &&
      email_unsubscribe== "false"
    ) {
      companyComponent = (
        <div class="table-actions">
          <a
            style="text-align: left !important;"
            onClick={() => openurl(id)}
            href="#"
          >
            <span>{Company}</span>
          </a>
        </div>
      );
    }
    else if (
      current_status == "Closed Won" && email_unsubscribe== "false"
    ){
      companyComponent = (
        <div class="table-actions">
          <a
            style="text-align: left !important;"
            onClick={() => showModal1()}
            href="#"
          >
            <span>{Company}</span>
          </a>
        </div>
      );
    }
    else if (
      current_status == "Closed Lost" || current_status== "Dead"
    ){
      companyComponent = (
        <div class="table-actions">
          <a
            style="text-align: left !important;"
            onClick={() => showModal()}
            href="#"
          >
            <span>{Company}</span>
          </a>
        </div>
      );
    }
     else {
      companyComponent = (
        <div class="table-actions">
          <a
            style="text-align: left !important;"
            onClick={() => showModal2()}
            href="#"
          >
            <span>{Company}</span>
          </a>
        </div>
      );
    }
          return {
            key: id,
            id: id,
            Company: companyComponent,
            Name:Name,
            email_unsubscribe:email_unsubscribe,
            Email_Address: Email_Address,
            user_id: user_id,
            current_status: current_status,
            } 
        }
        )
    );  
    return {
      empty,
      loading,
      TableColumns,
      TableData,
      TableColumns1,
      TableData1,
      selectedoption,
      DateSelect,
      selectrange,
      formState,
      handleOk,
      handleOk1,
      showModal,
      showModal1,
      showModal2,
      getdata,
      test,
      count1,
      count2,
      count3,
      count4,
      count5,
      count6,
      count7,
      count8,
      count9,
      count10,
      count11,
      count12,
      count13,
      count14,
      count15,
      dispData,
      dispSales,
      dispAdmin,
      dispTab,
      localState,
      handleCancel,
      dispPass,
      disProfile,
      };
  },
});
</script>
<style>
.ant-list-items {
  padding-top: 0px !important;
}


.highlighted {
  margin: 10px !important;
  border-spacing: 0 8px !important;
}

.product-list:hover {
  -webkit-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}

</style>