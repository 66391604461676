<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Fresh Data Upload
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%',}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="30">
            <a-col :md="4">
              <a-form-item name="center">
               <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                  
                </a-select> 
              </a-form-item>
            </a-col>
            <a-col :md="4">
            <a-form-item name="interest">
                <a-select
                  v-model:value="formState.interest"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in interestData"
                  v-bind:key="item.intrest_name"
                  :value="item.intrest_name"
                >
                  {{ item.intrest_name }}
                  </a-select-option>
                  
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="leadSource">
                <a-select
                  v-model:value="formState.leadSource"
                  style="width: 100%"
                  showSearch
                >
                  <a-select-option
                  v-for="item in sourceData"
                  v-bind:key="item.Source_type"
                  :value="item.Source_type"
                  >
                  {{ item.Source_type }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>
             <a-col :md="4">
              <a-form-item >
              <input ref="file" v-on:change="handleFileUpload()"  type="file" accept=".xlsx" />
              </a-form-item>
             </a-col>
            <a-col :md="4">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;margin-left: 20px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-up-circle" size="14" />  &nbsp; 
              Upload</span>
              </a-button>
             </a-form-item>
              </a-col>
              <a-col :md="4">
            <a-form-item>
            <a-button class="btn-add_new"
                style="margin-top: -1.5px !important;margin-left: 20px !important;" size="default"
                type="primary" @click="exportToCSV()">
              <span
              style="display: inline-flex !important;align-items: center !important;">
            <sdFeatherIcons type="download" size="14" /> &nbsp;
            Export</span
                      >
            </a-button> 
            </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    Leads List
    </sdHeading>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15">
          <a-col :md="24">
          <a-button
          size="default"
          type="primary"
          v-if="checkselected"
          @click="deleteBulk()"
          >
          <span style="display: inline-flex !important;
          align-items: center !important;">
          <sdFeatherIcons type="minus-circle" size="14" /> &nbsp;
          Delete Selected</span>
          </a-button>
          </a-col>
          </a-row>
          <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'AssignForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var interestData = ref();
    var sourceData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    const file = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.FreshData.data = [];
    onMounted(() => {
    getdata();
    }); 
    const formState = reactive({
      center: "Select Center",
      interest: "Select Interest",
      leadSource: "Select Lead Source",
    });
    const rules = {
      type: [
        {
          required: true,
          message: "Please select type first",
          trigger: "blur",
        },
      ],
      center: [
        {
          required: true,
          message: "Please select center first",
          trigger: "blur",
        },
      ],
      
      toUser: [
        {
          required: true,
          message: "Please select to User first!",
          trigger: "blur",
        },
      ],
      };
       const handleFileUpload = async() => {
           // debugger;
            console.log(file.value.files[0]);
           //state.FreshData.data= file.value.files[0];
            //Upload to server
        }
    const handleOk = (values) => {
      alertData();
    };
  const alertData = () => {
    let api_data = [];
    api_data['center'] = formState.center;
    api_data['interest'] = formState.interest;
    api_data['leadSource'] = formState.leadSource;
    api_data['selectedFile'] = file.value.files[0];
   dispatch("uploadFile",api_data).then(
            (response) => {
                if (response.status == 200) {
                 state.FreshData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "File Uploaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error: Entries in this are already existing in Database",
            });
          } 
          
        );
        }
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
const deleteBulk = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        
        loading.value = true;
        dispatch("deleteBulk",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Deleted.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Deleting Leads",
            });
          } 
          
        );
    }
const getdata = () => {
      loading.value = true;
      dispatch("getBulkFieldsData").then(

        (response) => {
          if (response.status == 200) {
            branchData.value = response.data[0];
            interestData.value = response.data[2];
            sourceData.value = response.data[1];
            loading.value = false;
          } else {
            state.FreshData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
          state.FreshData.data = [];
        }
      );
      
    };
  const TableColumns = [
    
      {
        title: "name",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "company",
        dataIndex: "company",
        key: "company",
      },
      {
        title: "board_nos",
        dataIndex: "board_nos",
        key: "board_nos",
      },
      {
        title: "phone",
        dataIndex: "phone",
        key: "phone",
      },
      {
        title: "mobile",
        dataIndex: "mobile",
        key: "mobile",
      },
      {
        title: "interest",
        dataIndex: "interest",
        key: "interest",
      },
      {
        title: "turn_over",
        dataIndex: "turn_over",
        key: "turn_over",
      },
      {
        title: "probability",
        dataIndex: "probability",
        key: "probability",
      },
      {
        title: "est_close_date",
        dataIndex: "est_close_date",
        key: "est_close_date",
      },
      {
        title: "forecast",
        dataIndex: "forecast",
        key: "forecast",
      },
      {
        title: "commission",
        dataIndex: "commission",
        key: "commission",
      },
      {
        title: "job_title",
        dataIndex: "job_title",
        key: "job_title",
      },
      {
        title: "website",
        dataIndex: "website",
        key: "website",
      },
      {
        title: "industry",
        dataIndex: "industry",
        key: "industry",
      },
      {
        title: "email_id",
        dataIndex: "email_id",
        key: "email_id",
      },
      {
        title: "lead_source",
        dataIndex: "lead_source",
        key: "lead_source",
      },
      {
        title: "address_1",
        dataIndex: "address_1",
        key: "address_1",
      },
      {
        title: "address_2",
        dataIndex: "address_2",
        key: "address_2",
      },
      {
        title: "address_3",
        dataIndex: "address_3",
        key: "address_3",
      },
      {
        title: "city",
        dataIndex: "city",
        key: "city",
      },
      {
        title: "county",
        dataIndex: "county",
        key: "county",
      },
      {
        title: "post_code",
        dataIndex: "post_code",
        key: "post_code",
      },
      {
        title: "country",
        dataIndex: "country",
        key: "country",
      },
      {
        title: "total_employees",
        dataIndex: "total_employees",
        key: "total_employees",
      },
      {
        title: "deparment_name",
        dataIndex: "deparment_name",
        key: "deparment_name",
      },
      {
        title: "opportunity_amt",
        dataIndex: "opportunity_amt",
        key: "opportunity_amt",
      },
      {
        title: "sub_industry",
        dataIndex: "sub_industry",
        key: "sub_industry",
      },
      {
        title: "management_level",
        dataIndex: "management_level",
        key: "management_level",
      },
      {
        title: "founded_year",
        dataIndex: "founded_year",
        key: "founded_year",
      },
      {
        title: "linkedin",
        dataIndex: "linkedin",
        key: "linkedin",
      },
      {
        title: "facebook",
        dataIndex: "facebook",
        key: "facebook",
      },
      {
        title: "twitter",
        dataIndex: "twitter",
        key: "twitter",
      },
      {
        title: "email_unsubscribe",
        dataIndex: "email_unsubscribe",
        key: "email_unsubscribe",
      },
      
    ];
  let TableData = computed(() =>
  state.FreshData.data.map((unreconcile) => {
    return {
      key: unreconcile[0], // Assuming `id` is at index 0
      name: unreconcile[0], // Prathamesh
      company: unreconcile[1], //  Analytix
      board_nos: unreconcile[2], // 1234567890
      phone: unreconcile[3], // NA
      mobile: unreconcile[4], // NA
      interest: unreconcile[5], // Bookkeeping
      turn_over: unreconcile[6], // NA
      probability: unreconcile[7], // NA
      est_close_date: unreconcile[8], // NA
      forecast: unreconcile[9], // NA
      commission: unreconcile[10], // NA
      job_title: unreconcile[11], // Manager
      website: unreconcile[12], // NA
      industry: unreconcile[13], // Accounting
      email_id: unreconcile[14], // mumtazadnan12552@gmail.com
      lead_source: unreconcile[15], // Data Mining
      address_1: unreconcile[16], // NA
      address_2: unreconcile[17], // NA
      address_3: unreconcile[18], // NA
      city: unreconcile[19], // NA
      county: unreconcile[20], // NA
      post_code: unreconcile[21], // NA
      country: unreconcile[22], // UK
      total_employees: unreconcile[23], // 100
      deparment_name: unreconcile[24], // NA
      opportunity_amt: unreconcile[25], // 500
      sub_industry: unreconcile[26], // NA (Extra fields)
      management_level: unreconcile[27], // NA
      founded_year: unreconcile[28], // NA
      linkedin: unreconcile[29], // linkedin.com
      facebook: unreconcile[30], // NA
      twitter: unreconcile[31], // NA
      email_unsubscribe: unreconcile[32],
    };
  })
);
const exportToCSV = () => {
      const inputData = state.FreshData.data.map((unreconcile) => {
        return {
      name: unreconcile[0], // Prathamesh
      company: unreconcile[1], //  Analytix
      board_nos: unreconcile[2], // 1234567890
      phone: unreconcile[3], // NA
      mobile: unreconcile[4], // NA
      interest: unreconcile[5], // Bookkeeping
      turn_over: unreconcile[6], // NA
      probability: unreconcile[7], // NA
      est_close_date: unreconcile[8], // NA
      forecast: unreconcile[9], // NA
      commission: unreconcile[10], // NA
      job_title: unreconcile[11], // Manager
      website: unreconcile[12], // NA
      industry: unreconcile[13], // Accounting
      email_id: unreconcile[14], // mumtazadnan12552@gmail.com
      lead_source: unreconcile[15], // Data Mining
      address_1: unreconcile[16], // NA
      address_2: unreconcile[17], // NA
      address_3: unreconcile[18], // NA
      city: unreconcile[19], // NA
      county: unreconcile[20], // NA
      post_code: unreconcile[21], // NA
      country: unreconcile[22], // UK
      total_employees: unreconcile[23], // 100
      deparment_name: unreconcile[24], // NA
      opportunity_amt: unreconcile[25], // 500
      sub_industry: unreconcile[26], // NA (Extra fields)
      management_level: unreconcile[27], // NA
      founded_year: unreconcile[28], // NA
      linkedin: unreconcile[29], // linkedin.com
      facebook: unreconcile[30], // NA
      twitter: unreconcile[31], // NA
      email_unsubscribe: unreconcile[32],
    };  
      });
      var linedata = [];
      const fileName ="Not Uploaded Leads Data Export";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(inputData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };  
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      interestData,
      sourceData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      file,
      deleteBulk,
      handleFileUpload,
      formState,
      rules,
      exportToCSV,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>