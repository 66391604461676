<template>
  <Main>
    <template v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </template>
    <template v-if="!loading">
      <a-col :xs="24"> <UserListTable :tabledata="usersTableData" @eventname="emittest" /> </a-col>
    </template>
  </Main>
</template>

<script>
import { defineComponent } from 'vue';
import { Main, CardToolbox } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
// import UserListTable from '../apps/users/overview/UserTable.vue';
import UserListTable from './UserTable.vue';
export default defineComponent({
  name: 'UserMaster',
  components: {
    Main,
    UserListTable,
    CardToolbox,
  },
  setup() {
    var usersTableData = ref([]);
    var loading = ref(true);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    var userdata = null;
    var usercount = ref(null);
    var searchData = ref([]);
    var users = [];

    onMounted(() => {
      getusers();
    });

    const emittest = (childData) => {
      // alert(childData);
      getusers();
    };

    const getusers = () => {
      dispatch('getuserslist').then(
        (response) => {
          userdata = response.data.userdata;
          for (let i = 0; i < userdata.length; i++) {
            userdata[i].status;
            switch (userdata[i].status) {
              case 'A':
                userdata[i].status = 'active';
                break;
              case 'B':
                userdata[i].status = 'blocked';
                break;
              case 'D':
                userdata[i].status = 'deactivate';
                break;
              case 'N':
                userdata[i].status = 'blocked';
                break;
              default:
                break;
            }
            userdata[i].fullname = userdata[i].f_name + ' ' + userdata[i].l_name;
          }
          usersTableData.value = userdata;
          dispatch('userGetData', userdata);
          usercount.value = userdata.length;
          var testdata = [];
          for (let i = 0; i < userdata.length; i++) {
            var obj = {};
            obj['id'] = i;
            obj['title'] = userdata[i].fullname;
            testdata.push(obj);
          }
          searchData = computed(() => testdata);
          loading.value = false;
        },
        (error) => {
          userdata = [];
          loading.value = false;
          console.log(error);
        },
      );
    };

    return { usersTableData, loading, searchData, usercount, emittest };
  },
});
</script>
