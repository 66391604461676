<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           New Leads From Websites
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%',}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              ref="form"
              name="contact"
              :rules  ="rules"
              :model="formState"
              @submit.prevent="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="30">
            <a-col :md="4">
            <a-form-item name="center">
                <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  placeholder="Select Branch Code"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                  
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
            <a-form-item name="industry">
            <a-select
                v-model:value="formState.industry"
                style="width: 100%"
                placeholder="Select Industry"
                showSearch
                >
                <a-select-option
                  v-for="item in industryData"
                  v-bind:key="item.vertical_type"
                  :value="item.vertical_type"
                >
                  {{ item.vertical_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col> 
            <a-col :md="5" >
            <a-form-item name="source">
            <a-select
                v-model:value="formState.source"
                style="width: 100%"
                placeholder="Select Lead Source"
                showSearch
                >
                <a-select-option
                  v-for="item in sourceData"
                  v-bind:key="item.Source_type"
                  :value="item.Source_type"
                >
                  {{ item.Source_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col> 
            <a-col :md="5">
              <a-form-item name="interest">
                <a-select
                v-model:value="formState.interest"
                style="width: 100%"
                placeholder="Select Interest"
                showSearch
                >
                <a-select-option value="Accounts">Accounts</a-select-option>
                <a-select-option value="Power BI">Power BI</a-select-option>
                <a-select-option value="Intent">Intent</a-select-option>
                <a-select-option value="Websights">Websights</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="toUser">
                <a-select
                  v-model:value="formState.toUser"
                  style="width: 100%"
                  placeholder="Select User to Assign"
                  showSearch
                >
                  <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                  >
                  {{ item.user_id }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>
             <a-col :md="4">
             <a-form-item>
              <a-button
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                v-if="checkselected"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" /> &nbsp;
              Approve Leads</span>
              </a-button>
              </a-form-item>
              </a-col>
              <a-col :md="4">
             <a-form-item>
              <a-button
                size="default"
                type="primary"
                v-if="checkselected"
                class="btn-add_new"
                @click="rejectLeads()"
                style="margin-top: -1.5px !important;"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" /> &nbsp;
              Reject Leads</span>
              </a-button>
              </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <a-row :gutter="15" style="padding: 10px">
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    Leads List
    </sdHeading>

    <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
            </a-row>
      <template v-if="selectedoption == 'Active'">
       
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 50,
                    total: TableData.length,
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200','500','1000'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'AssignForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var userData = ref();
    var industryData = ref();
    var sourceData = ref();
    var dispIndustry = ref(true);
    var dispStatus = ref(false);
    var dispFromUser = ref(false);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    const selectedChange = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.NewData.data = [];
    var NewData1= [];
    onMounted(() => {
    loaddData();
    loadData();
    });
    const form = ref(null);
    const formState = reactive({
      center: null,
      toUser: null,
      industry: null,
      interest: null,
      source: null,
    });
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    const handleSearch = (values) => {
  const substring = addFormState.search_text.toLowerCase();
  const leadsData = NewData1.data;
  
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.name.toLowerCase().includes(substring) ||
    data.company.toLowerCase().includes(substring) || 
    data.email.toLowerCase().includes(substring)
  );
  
  // Update the state with filtered data
  state.NewData.data = filteredData;
};
const handleOk = (values) => {
    if (form.value) {
    // Validate the form before proceeding
    form.value.validate((valid) => {
      if (valid) {
        approveLeads();
      } else {
        console.error("Form validation failed");
        return false;
      }
    });
  } else {
    console.error("Form reference is not available");
  }
};
    const rules = {
      center: [
        {
          required: true,
          message: "Please select center",
          trigger: "blur",
        },
      ],
      toUser: [
        {
          required: true,
          message: "Please select User to Assign",
          trigger: "blur",
        },
      ],
      industry: [
        {
          required: true,
          message: "Please select industry",
          trigger: "blur",
        },
      ],
      interest: [
        {
          required: true,
          message: "Please select interest",
          trigger: "blur",
        },
      ],
      source: [
        {
          required: true,
          message: "Please select lead source",
          trigger: "blur",
        },
      ],
      };
    
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
    const approveLeads = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        api_data['center'] = formState.center;
        api_data['toUser'] = formState.toUser;
        api_data['industry'] = formState.industry;
        api_data['interest'] = formState.interest;
        loading.value = true;
        dispatch("approveLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Approved.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Assigning Leads",
            });
          } 
          
        );
    }
const rejectLeads = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        loading.value = true;
        dispatch("rejectLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Rejected.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Assigning Leads",
            });
          } 
          
        );
    }
    const loaddData = () => {
        loading.value = true;
        dispatch("manualFieldsData").then(
            (response) => {
                if (response.status == 200) {
                    industryData.value = response.data[0];
                    branchData.value = response.data[3];
                    userData.value = response.data[5];
                    sourceData.value = response.data[4];
                } else {
                loading.value = false;
                       }
            },
            (error) => {
                loading.value = false;
            } 
          
        );
    }
    const loadData = () => {
        let api_data = [];
        loading.value = true;
        dispatch("loadNewLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    state.NewData.data = response.data;
                    NewData1.data = response.data;
                    //localStorage.setItem("search_data1",JSON.stringify(response.data));
                    console.log(response.data);
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.NewData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
    }
    const TableColumns = [
      {
        title: "Name",
        dataIndex: "name",
        key: "name",
        sorter: (a, b) => a.name.length - b.name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company Name",
        dataIndex: "company",
        key: "company",
        sorter: (a, b) => a.company.length - b.company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
        sorter: (a, b) => a.email.length - b.email.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Source",
        dataIndex: "source",
        key: "source",
        sorter: (a, b) => a.source.length - b.source.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
        sorter: (a, b) => a.message.length - b.message.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Date",
        dataIndex: "date",
        key: "date",
        sorter: (a, b) => a.date.length - b.date.length,
        sortDirections: ["descend", "ascend"],
      },
      
      
    ];
   
     let TableData = computed(() =>
        state.NewData.data.map((unreconcile) => {
          const {
            id,
            company,
            name,
            email,
            form_id,
            message,
            submission_id,
            date,
            phone,
            source,
            } = unreconcile;
          const maxLength = 50;
          const maxLength1 = 20;
          return {
            key: id,
            id: id,
            company:  company.length > maxLength1 ? company.substring(0, maxLength1) + "..." : company,
            name:name,
            email: email,
            form_id: form_id,
            // Truncate the message if it exceeds maxLength
            message: message.length > maxLength ? message.substring(0, maxLength) + "..." : message,
            date: date,
            submission_id: submission_id,
            phone: phone,
            source: source,
            } 
            
        }
        )
    );
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      userData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      loadData,
      formState,
      rules,
      approveLeads,
      rejectLeads,
      addFormState,
      handleSearch,
      NewData1,
      industryData,
      sourceData,
      loaddData,
      dispIndustry,
      dispStatus,
      dispFromUser,
      handleOk,
      form,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>