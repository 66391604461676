<template>
    <div v-if="loading">
        <sdCards headless>
        <div class="spin">
            <a-spin />
        </div>
        </sdCards>
    </div>
    <div v-else>
        <!-- <sdPageHeader title="All Clients">
        <template v-slot:subTitle>
            <a-button size="default" type="text" style="border: none">
            <h3 style="color: black !important">Favourites</h3>
            </a-button>
            <a-button
            size="default"
            type="text"
            style="
                border: none;
                vertical-align: middle;
                transform: rotateZ(270deg);
            "
            >
            <span>
                <sdFeatherIcons
                style="line-height: 0; color: black !important"
                type="bar-chart"
                size="18"
            /></span>
            </a-button>
        </template>
        </sdPageHeader> -->
        <Main>
            <div :style="{'margin-top':'45px'}">
                <div class="auth-contents">
                    <a-form name="register" :model="formState" @finish="handleSubmit" layout="vertical">
                    <sdHeading as="h3"> Update <span class="color-secondary">Profile</span> </sdHeading>
                    <a-form-item label="First Name" name="f_name">
                    <a-input v-model:value="formState.f_name"  placeholder="First name" />
                    </a-form-item>
                    <a-form-item name="l_name" label="Last Name">
                    <a-input v-model:value="formState.l_name" placeholder="Last Name" />
                    </a-form-item>
                    <a-form-item label="Profile Photo" name="photo">
                        <img :src="formState.profile_pics"
                         style="
                        width: 50px;
                        height: 50px;
                        " alt="">
                        <input type="file" name="photo" @change="handleFileInput" >
                    <!-- <a-input v-model:value="formState.f_name"  placeholder="First name" /> -->
                    </a-form-item>
                    <a-form-item name="email" label="Email Address">
                    <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" />
                    </a-form-item>
                    <a-form-item label="New Password" name="password">
                    <a-input type="password" v-model:value="formState.password" placeholder="Password" />
                    </a-form-item>
                    <a-form-item label="Confirm Password" name="confirm_password">
                    <a-input type="password" v-model:value="formState.confirm_password" placeholder="Confirm Password" />
                    </a-form-item>
                    <a-form-item>
                    <button class="btn-create vue-btn" @click="accountadd" htmlType="submit" type="primary" size="large">Update</button>
                    </a-form-item>
                </a-form>
                </div>
            </div>

        </Main>
    </div>
  </template>
  
  <script>
    import { defineComponent } from 'vue';
    import { Main, CardToolbox } from '../../styled';
    import { dispatch, useStore } from 'vuex';
    import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
    import { useRouter, useRoute } from 'vue-router';
    import { FacebookOutlined, TwitterOutlined } from '@ant-design/icons-vue';
    import { message, Notification } from "ant-design-vue";
  export default defineComponent({
    name: 'Profile',
    components: {
      Main,
      CardToolbox,
    },
    setup() {
        const { state, dispatch } = useStore();
        var isLoading = computed(() => state.auth.loading);
        const router = useRouter();
        const values = ref(null);
        const checked = ref(null);
        var message = ref(null);
        const handleSubmit = (value) => {
            values.value = value;
            // isLoading = true;
            // console.log(formState);
            let data = [];
            data['form_data'] = formState;
            data['photo'] = formState.photo;
            dispatch('updateProfile', formState).then(
                ( ) => {
                Notification["success"]({
                    message: "Success!",
                    description: "Profile Updated Successfully!",
                });
                isLoading = false;
                state.auth.login = true;
                router.push('/profile');
                },
                (error) => {
                console.log(error);
                
                var errors = (JSON.parse(error.data));
                if(errors.f_name){
                    Notification["error"]({
                    message: "Error!",
                    description: errors.f_name[0],
                    });
                }
                if(errors.l_name){
                    Notification["error"]({
                    message: "Error!",
                    description: errors.l_name[0],
                    });
                }
                if(errors.email){
                    Notification["error"]({
                    message: "Error!",
                    description: errors.email[0],
                    });
                }
                if(errors.password){
                    Notification["error"]({
                    message: "Error!",
                    description: errors.password[0],
                    });
                }
                if(errors.selectedFile){
                    Notification["error"]({
                    message: "Error!",
                    description: errors.selectedFile[0],
                    });
                }
                // errors.forEach((value, index) => {
                //     // arr.push(value);
                //     console.log(value);
                //     console.log(index);
                // });
                if (typeof error != 'string') {
                    // console.log(error.data.error.toString());
                    isLoading = false;
                    // message.value = error.data.error.toString();
                    message.value = 'Wrong email id or password';
                } else {
                    isLoading = false;
                    // message.value = 'Error Comunicating To Server';
                    message.value = 'Invalid Credentials';
                }

                // console.log(error.data.error.toString());
                // isLoading = false;
                // // message.value = error.data.error.toString();
                // message.value = 'Wrong email id or password';
                },
            );
        // console.log(values);
        }
  
        const onChange = (check) => {
            checked.value = check;
        };

        const handleFileInput = (e) => {
            let files = e.target.files[0];
            formState.photo = files;
            // console.log(files);
            // setSelectedFile(files);
        }

        const user_data = typeof window !== "undefined" ? JSON.parse(localStorage.user) : "";

        const formState = reactive({
            f_name: user_data.f_name,
            l_name: user_data.l_name,
            email: user_data.email,
            photo: user_data.profile_pic,
            profile_pics: user_data.profile_pic,
            id: user_data.id,
            password: '',
            confirm_password: '',
        });
    return {
      onChange,
      handleSubmit,
      formState,
      handleFileInput,
    };
    },
  });
  </script>
  