<template>
  <AuthWrapper>
    <div class="auth-contents">

      <a-form @submit="handleSubmit" :model="formState" layout="vertical">

        <sdHeading as="h3">Send Request for a Proposal</sdHeading>
        <p class="forgot-text">
          Enter the email address and we’ll send you instructions and link to fill RFP Questionnaire.
        </p>
        <a-form-item label="Company Name" name="name">
          <a-input type="text" v-model:value="formState.name" placeholder="Add Company Name" required />
        </a-form-item>
        <a-form-item label="Email Address" name="email">
          <a-input type="email" v-model:value="formState.email" placeholder="name@example.com" required />
        </a-form-item>
        <a-form-item>
          <a-button class="btn-signin" htmlType="submit" type="primary" size="large"> Send Instructions </a-button>
        </a-form-item>
        <p class="return-text">Return to <router-link to="/">Dashboard</router-link></p>
      </a-form>
    </div>
  </AuthWrapper>
</template>
<script>
import { reactive, defineComponent } from 'vue';
import { AuthWrapper } from './style';
import { message, Notification } from "ant-design-vue";
import { useStore } from "vuex";

const ForgotPassword = defineComponent({
  name: 'ForgotPassword',
  components: { AuthWrapper },
  setup() {
    const { state, dispatch } = useStore();
    
    const handleSubmit = (values) => {
      //alert(values);
      //localStorage.setItem("email_rfp", formState.email);
      let api_data = [];
      api_data['email'] = formState.email;
      api_data['name'] = formState.name;
      dispatch("sendRfpLink", api_data).then(
        (response) => {
          if (response.status == 200) {
            //location.reload();
            Notification["success"]({
              message: "Succsess!",
              description: "We have e-mailed your form link!.",
            });
            
            
          }
        },
        (error) => {
          if (error.status == 201) {
          Notification["error"]({
              message: "Error!",
              description: "The Email id is not registered.",
            });
          }
        }
      );
    };

    const formState = reactive({
      email: '',
      name: '',
    });


    return {
      handleSubmit,
      formState,
    };
  },
});

export default ForgotPassword;
</script>
