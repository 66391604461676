<template>
   <!--<sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Data
          </sdHeading>
        </div>
       </template>
   </sdPageHeader>-->
  <Main>
    <sdCards headless>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15" style="padding: 10px">
       <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Data
          </sdHeading>
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                        <a-col>
                          <a-form-item name="search_text">
                            <a-button class="btn-signin" size="small" @click="exportToCSV()">
                          <span
                          style="
                          display: inline-flex !important;
                          align-items: center !important;
                          "
                          >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                          </a-form-item>
                        </a-col>
                        <a-col v-if="dispMail">
                          <a-form-item name="search_text">
                            <a-button class="btn-signin" size="small" @click="leadmail()">
                          <span
                          style="
                          display: inline-flex !important;
                          align-items: center !important;
                          "
                          >
                        <sdFeatherIcons type="send" size="14" /> &nbsp;
                        Mail Sent</span
                      >
                    </a-button>
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :columns="TableColumns"
                :rowSelection="rowSelection"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 50,
                    total: TableData.length,
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200','500','1000'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { setItem, getItem, removeItem } from '/src/utility/localStorageControl';
export default defineComponent({
  name: 'CentersForm',
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var dataStatus= ref(null);
    var dispMail= ref(false);
    var linedata = [];
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    var supplierdrop = ref();
    var contact_id = ref(null);
    const route = useRoute();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    onMounted(() => {
    getdata(route.params.id);
    
    }); 
    state.LeadsData.data = [];
    var LeadsData1 = [];
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    state.LeadsData.data = [];
    const viewMail = () => {
    if(dataStatus.value=="Target In-Progress"){
    dispMail.value=true;
    }
    else {
    dispMail.value=false;
    }
    };
const getdata = (id) => {
      loading.value = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getLeadsDataIndexx",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsData.data = response.data;
            LeadsData1.data = response.data;
            dataStatus.value= response.data[0].current_status;
            viewMail();
            //localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.LeadsData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.LeadsData.data = [];
        }
      );
    };
    const leadmail = () => {
      loading.value = true;
      let api_data = [];
        api_data['ids'] = selectedid;
      dispatch("leadMailSent",api_data).then(
        (response) => {
          if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Lead's Added to Mail Sent Tab.",
                    });
                    location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                }
        },
        (error) => {
          loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Updating Lead",
            });
            location.reload();
        }
      );
    };
const openurl = (id) => {
      let url = '/index/';
      window.open(
          url + id +'/0',
          "_self",
      ); 
      
    };
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
  const handleSearch = (values) => {
  const substring = addFormState.search_text.toLowerCase();
  //const leadsData = JSON.parse(localStorage.getItem("search_data1"));
  const leadsData =LeadsData1.data;
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.Name.toLowerCase().includes(substring) || 
    data.Email_Address.toLowerCase().includes(substring)||
    data.datetime.toLowerCase().includes(substring)||
    data.Industry.toLowerCase().includes(substring)||
    data.interest.toLowerCase().includes(substring)
  );
  
  // Update the state with filtered data
  state.LeadsData.data = filteredData;
};
    const TableColumns = [
     {
        title: "Date Time",
        dataIndex: "datetime",
        key: "datetime",
        sorter: (a, b) => new Date(a.datetime) - new Date(b.datetime),
        sortDirections: ["descend", "ascend"],
      },
     {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Industry",
        dataIndex: "Industry",
        key: "Industry",
        sorter: (a, b) => a.Industry.length - b.Industry.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Interest",
        dataIndex: "interest",
        key: "interest",
        sorter: (a, b) => a.interest.length - b.interest.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Telephone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Cell Phone",
        dataIndex: "Mobile",
        key: "Mobile",
        sorter: (a, b) => a.Mobile.length - b.Mobile.length,
        sortDirections: ["descend", "ascend"],
      },
      //{
      //  title: "Designation",
      //  dataIndex: "Job_Title",
      //  key: "Job_Title",
      //  sorter: (a, b) => a.Job_Title.length - b.Job_Title.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      //{
      //  title: "Value",
      //  dataIndex: "Forecast",
      //  key: "Forecast",
      //  sorter: (a, b) => a.Forecast.length - b.Forecast.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      //{
      //  title: "Probability",
      //  dataIndex: "Probability",
      //  key: "Probability",
      //  sorter: (a, b) => a.Probability.length - b.Probability.length,
      //  sortDirections: ["descend", "ascend"],
      //},
      {
        title: "Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
    ];
  let TableData = computed(() =>
        state.LeadsData.data.map((unreconcile) => {
          const {
            id,
            Company,
            Name,
            interest,
            Industry,
            user_id,
            Probability,
            Job_Title,
            phone,
            Mobile,
            turn_over,
            Forecast,
            Email_Address,
            current_status,
            Action,
            datetime,
            } = unreconcile;
          return {
            key: id,
            id: id,
            Probability: Probability,
            Job_Title:Job_Title,
            phone:phone,
            Mobile:Mobile,
            Email_Address:Email_Address,
            turn_over:turn_over,
            Forecast:Forecast,
            datetime:datetime,
            Industry: Industry,
            interest: interest,
            Company: (
            <div class="table-actions">
              <>
                <a style="text-align: left !important;"onClick={() => openurl(id)} to="#">
                  <span>
                    {Company}
                    </span>
                </a>
              </>
            </div>
          ),
            Name:Name,
            user_id: user_id,
            current_status: current_status,
            Action: Action,
            } 
        }
        )
    );
  const exportToCSV = () => {
      const filteredData = state.LeadsData.data.filter(item => selectedid.value.includes(item.id));
      const inputData = filteredData.map((unreconcile) => {
        const {
            id,
            Company,
            Name,
            interest,
            Industry,
            user_id,
            Probability,
            Job_Title,
            phone,
            Mobile,
            turn_over,
            Forecast,
            Email_Address,
            current_status,
            Action,
            datetime,
            
        } = unreconcile;
        return {
            key: id,
            datetime:datetime,
            Company: Company,
            Name:Name,
            Industry: Industry,
            Email_Address:Email_Address,
            Interest: interest,
            Probability: Probability,
            Job_Title:Job_Title,
            phone:phone,
            Mobile:Mobile,
            turn_over:turn_over,
            Forecast:Forecast,
            user_id: user_id,
            current_status: current_status,
            Action: Action,
          };
      });

      var linedata = [];

      
      
      const fileName ="Lead Data Export";
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(inputData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };  
  return {
      loading,
      TableColumns,
      TableData,
      selectcount,
      checkselected,
      selectedoption,
      getdata,
      openurl,
      supplierdrop,
      orgdata,
      route,
      addFormState,
      handleSearch,
      LeadsData1,
      rowSelection,
      exportToCSV,
      dataStatus,
      dispMail,
      viewMail,
      leadmail,
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
</style>