<template>
  <Main>
    <template v-if="loading">
      <div class="spin">
        <a-spin />
      </div>
    </template>
    <template v-if="!loading">
      <a-col :xs="24"> 
        <RoleListTable :tabledata="roleTableData" @eventname="emittest" /> </a-col>
    </template>
  </Main>
</template>

<script>
import { defineComponent } from 'vue';
import { Main, CardToolbox } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
// import UserListTable from '../apps/users/overview/UserTable.vue';
import RoleListTable from './RoleTable.vue';
export default defineComponent({
  name: 'RoleMaster',
  components: {
    Main,
    RoleListTable,
    CardToolbox,
  },
  setup() {
    var roleTableData = ref([]);
    var loading = ref(true);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT;
    var userdata = ref(null);
    var usercount = ref(null);
    var searchData = ref([]);
    var users = [];

    onMounted(() => {
      
      getrole();
    });

    const emittest = (childData) => {
      // alert(childData);
      getrole();
    };

    const getrole = () => {
      dispatch('roleData').then(
        (response) => {
          dispatch('roleGetData', response.data);
          roleTableData.value = response.data; 
          loading.value = false;
        },
        (error) => {
          console.log(error);
        },
      );
    };

    return { roleTableData, loading, searchData, usercount, emittest };
  },
});
</script>
