<template>
   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Open Data
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '10px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="20">
            <a-col :md="5">
              <a-form-item name="center">
                <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in campData"
                  v-bind:key="item.campaign_name"
                  :value="item.campaign_id"
                >
                  {{ item.campaign_name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-down-circle" size="14" />  &nbsp; 
              Get Open's</span>
              </a-button>
             </a-form-item>
              </a-col>
             <a-col :md="5">
            <a-form-item>
              <a-button
                size="default"
                type="primary"
                class="btn-add_new"
                @click="getcampdetails()"
                style="margin-top: -1.5px !important;"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" /> &nbsp;
              Load Campaign</span>
              </a-button>
             </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
  <Main>
    <sdCards headless>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15" style="padding: 10px">
       <!--<sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Data
          </sdHeading>-->
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { setItem, getItem, removeItem } from '/src/utility/localStorageControl';
export default defineComponent({
  name: 'ClickSprintForm',
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var campData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var linedata = [];
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    var supplierdrop = ref();
    var contact_id = ref(null);
    const route = useRoute();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    onMounted(() => {
    //getcampdetails();
    getdata(route.params.id);
    getcampdata();
    }); 
    state.LeadsData.data = [];
    var LeadsData1 = [];
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    state.LeadsData.data = [];
    const formState = reactive({
      center: "Select Campaign",
      });
    const rules = {
      center: [
        {
          required: true,
          message: "Please select criteria first",
          trigger: "blur",
        },
      ],
      };
   
    const handleOk = (values) => {
     loadcampdetails(); 
    };
const getdata = (id) => {
      loading.value = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getLeadsDataIndexx",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsData.data = response.data;
            LeadsData1.data = response.data;
            //localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.LeadsData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.LeadsData.data = [];
        }
      );
    };
const getcampdata = () => {
      loading.value = true;
      dispatch("getAssignFieldsData").then(
        (response) => {
          if (response.status == 200) {
            campData.value = response.data[2];
            loading.value = false;
          } else {
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
        }
      );
      
    };
const getcampdetails = () => {
      loading.value = true;
      dispatch("getCampDetails").then(
        (response) => {
          if (response.status == 200) {
           loading.value = false;
           location.reload();
          } else {
            loading.value = false;
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
        }
      );
      
    };
const loadcampdetails = () => {
      loading.value = true;
      let api_data = [];
      api_data['campaign_id'] = formState.center;
      dispatch("loadCampDetails",api_data).then(
        (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Opened Leads Loaded.",
                    });
                    //location.reload();
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
      );
      
    };        
const openurl = (id) => {
      let url = '/index/';
      window.open(
          url + id,
          "_self",
      ); 
      
    };
    const updateComplete = (id,ids) => {
    let api_data = [];
      api_data['ids'] = id;
      api_data['source']=ids;
      dispatch("getClicksCompleteStatus",api_data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
                    message: "Succsess!",
                    description: "Clicks Completed Successfully.",
            });
          } else {
            Notification["error"]({
                message: "Error!",
                description: "Error Completing Clicks.",
            });
          }
        },
        (error) => {
          Notification["error"]({
                message: "Error!",
                description: "Error Completing Clicks.",
            });
        }
      );

    };
  const handleSearch = (values) => {
  const substring = addFormState.search_text.toLowerCase();
  //const leadsData = JSON.parse(localStorage.getItem("search_data1"));
  const leadsData =LeadsData1.data;
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.Name.toLowerCase().includes(substring) || 
    data.Email_Address.toLowerCase().includes(substring) || 
    data.clicked_date.toLowerCase().includes(substring)  ||
    data.category.toLowerCase().includes(substring)  ||
    data.branch_code.toLowerCase().includes(substring)||
    data.campaign_name.toLowerCase().includes(substring)

  );
  
  // Update the state with filtered data
  state.LeadsData.data = filteredData;
};
    const TableColumns = [
     {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Category",
        dataIndex: "category",
        key: "category",
        sorter: (a, b) => a.category.length - b.category.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Branch Code",
        dataIndex: "branch_code",
        key: "branch_code",
        sorter: (a, b) => a.branch_code.length - b.branch_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        sorter: (a, b) => a.campaign_name.length - b.campaign_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Opened Date",
        dataIndex: "clicked_date",
        key: "clicked_date",
        sorter: (a, b) => new Date(a.clicked_date) - new Date(b.clicked_date),
        sortDirections: ["descend", "ascend"],
      },
      //{
      //  title: "Completed",
      //  dataIndex: "complete",
      //  key: "complete",
      //  sorter: (a, b) => a.complete.length - b.complete.length,
      //  sortDirections: ["descend", "ascend"],
      //},
    ];
  let TableData = computed(() =>
        state.LeadsData.data.map((unreconcile) => {
          const {
            id,
            Company,
            Name,
            Email_Address,
            current_status,
            Action,
            campaign_name,
            mailing_server,
            clicked_date,
            category,
            branch_code,
            } = unreconcile;
          return {
            key: id,
            id: id,
            Email_Address:Email_Address,
            category: category,
            branch_code: branch_code,
            Company: Company,
          complete: (
            <div class="table-actions">
              <>
                <a onClick={() => updateComplete(id,route.params.id)} to="#">
                  <span style="display: inline-flex !important; align-items: center !important;">
                    <sdFeatherIcons
                      type="trash-2"
                      size="22"
                      color="#1890ff"
                    />{" "}
                    &nbsp;Dispose
                  </span>
                </a>
              </>
            </div>
          ),
            Name:Name,
            current_status: current_status,
            Action: Action,
            campaign_name:campaign_name,
            mailing_server:mailing_server,
            clicked_date:clicked_date,
            } 
        }
        )
    );
  return {
      loading,
      TableColumns,
      TableData,
      selectcount,
      checkselected,
      selectedoption,
      getdata,
      openurl,
      supplierdrop,
      orgdata,
      route,
      addFormState,
      handleSearch,
      LeadsData1,
      updateComplete,
      formState,
      rules,
      campData,
      handleOk,
      getcampdetails,
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
</style>