<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Existing Data Clicks/Open Update
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%',}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="30">
            <a-col :md="4">
              <a-form-item name="center">
               <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                  
                </a-select> 
              </a-form-item>
            </a-col>
            
             <a-col :md="4">
              <a-form-item >
              <input ref="file" v-on:change="handleFileUpload()"  type="file" accept=".xlsx" />
              </a-form-item>
             </a-col>
            <a-col :md="4">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;margin-left: 20px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-up-circle" size="14" />  &nbsp; 
              Upload</span>
              </a-button>
             </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    Leads List
    </sdHeading>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15">
          <a-col :md="24">
          <a-button
          size="default"
          type="primary"
          v-if="checkselected"
          @click="deleteBulk()"
          >
          <span style="display: inline-flex !important;
          align-items: center !important;">
          <sdFeatherIcons type="minus-circle" size="14" /> &nbsp;
          Delete Selected</span>
          </a-button>
          </a-col></a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'DataClickUpdateBulkForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var interestData = ref();
    var sourceData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    const file = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.FreshData.data = [];
    onMounted(() => {
    getdata();
    }); 
    const formState = reactive({
      center: "Select Center",
      });
    const rules = {
      type: [
        {
          required: true,
          message: "Please select type first",
          trigger: "blur",
        },
      ],
      center: [
        {
          required: true,
          message: "Please select center first",
          trigger: "blur",
        },
      ],
      
      
      };
       const handleFileUpload = async() => {
           // debugger;
            console.log(file.value.files[0]);
           //state.FreshData.data= file.value.files[0];
            //Upload to server
        }
    const handleOk = (values) => {
      alertData();
    };
  const alertData = () => {
    let api_data = [];
    api_data['center'] = formState.center;
    api_data['selectedFile'] = file.value.files[0];
   dispatch("updateClickLeadFile",api_data).then(
            (response) => {
                if (response.status == 200) {
                 state.FreshData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "File Uploaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error: Entries in this are already existing in Database",
            });
          } 
          
        );
        }
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
const deleteBulk = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        
        loading.value = true;
        dispatch("deleteBulk",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Deleted.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Deleting Leads",
            });
          } 
          
        );
    }
const getdata = () => {
      loading.value = true;
      dispatch("getBulkFieldsData").then(

        (response) => {
          if (response.status == 200) {
            branchData.value = response.data[0];
            interestData.value = response.data[2];
            sourceData.value = response.data[1];
            loading.value = false;
          } else {
            state.FreshData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
          state.FreshData.data = [];
        }
      );
      
    };
  const TableColumns = [
    
      {
        title: "Id",
        dataIndex: "id",
        key: "id",
        sorter: (a, b) => a.id.length - b.id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Campaign Name",
        dataIndex: "campaign_name",
        key: "campaign_name",
        sorter: (a, b) => a.campaign_name.length - b.campaign_name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email_Address",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Clicked Date",
        dataIndex: "clicked_date",
        key: "clicked_date",
        sorter: (a, b) => a.clicked_date.length - b.clicked_date.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Mail Server",
        dataIndex: "mailing_server",
        key: "mailing_server",
        sorter: (a, b) => a.mailing_server.length - b.mailing_server.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
  let TableData = computed(() =>
        state.FreshData.data.map((unreconcile) => {
          const {
            id,
            Name,
            Company,
            Email_Address,
            campaign_name,
            mailing_server,
            clicked_date
            } = unreconcile;
          return {
            key: id,
            id: id,
            Name:Name,
            Company: Company,
            Email_Address: Email_Address,
            campaign_name:campaign_name,
            mailing_server:mailing_server,
            clicked_date:clicked_date,
            } 
            
        }
        )
    );
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      interestData,
      sourceData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      file,
      deleteBulk,
      handleFileUpload,
      formState,
      rules,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>