<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Assign/Re-Assign Leads
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%',}"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="30">
            <a-col :md="4">
              <a-form-item name="assign">
                <a-select
                v-model:value="formState.type"
                style="width: 100%"
                @change="onChange"
                >
                <a-select-option value="assign">Assign</a-select-option>
                <a-select-option value="reassign">Re-Assign</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4">
            <a-form-item name="center">
                <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                  
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5" v-if="dispIndustry">
            <a-form-item name="industry">
            <a-select
                v-model:value="formState.industry"
                style="width: 100%"
                showSearch
                >
                <a-select-option
                  v-for="item in industryData"
                  v-bind:key="item.vertical_type"
                  :value="item.vertical_type"
                >
                  {{ item.vertical_type }}
                  </a-select-option>
            </a-select>    
            </a-form-item>
            </a-col> 
            <a-col :md="5" v-if="dispStatus">
              <a-form-item name="status">
                <a-select
                v-model:value="formState.status"
                style="width: 100%"
                showSearch
                >
                <a-select-option value="Target Assignment">Fresh Target</a-select-option>
                <a-select-option value="Opportunity Assignment">Fresh Opportunity</a-select-option>
                <a-select-option value="Target In-Progress">Assigned Target</a-select-option>
                <a-select-option value="Mail Sent">Mail Sent</a-select-option>
                <a-select-option value="Opportunity In-Progress">Assigned Opportunity</a-select-option>
                <a-select-option value="Dead">Dead Lead</a-select-option>
                <a-select-option value="Lead In-Progress"> Lead In-Progress</a-select-option>
                <a-select-option value="Proposal">Proposal Review</a-select-option>
                <a-select-option value="Negotiation Review">Negotiation Review</a-select-option>
                <a-select-option value="Closed Lost"> Closed Lost</a-select-option>
                <a-select-option value="Closed Won">Closed Won</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="interest">
                <a-select
                v-model:value="formState.interest"
                style="width: 100%"
                showSearch
                >
                <a-select-option value="Accounts">Accounts</a-select-option>
                <a-select-option value="Power BI">Power BI</a-select-option>
                <a-select-option value="Intent">Intent</a-select-option>
                <a-select-option value="Websights">Websights</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4" v-if="dispFromUser">
              <a-form-item name="fromUser">
                <a-select
                  v-model:value="formState.fromUser"
                  style="width: 100%"
                  showSearch
                >
                  <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                  >
                  {{ item.user_id }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>
             <a-col :md="4">
              <a-form-item name="toUser">
                <a-select
                  v-model:value="formState.toUser"
                  style="width: 100%"
                  showSearch
                >
                  <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                  >
                  {{ item.user_id }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>
            <a-col :md="4">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-down-circle" size="14" />  &nbsp; 
              Load Leads</span>
              </a-button>
             </a-form-item>
              </a-col>
             <a-col :md="4">
             <a-form-item>
              <a-button
                size="default"
                type="primary"
                v-if="checkselected"
                class="btn-add_new"
                @click="assignLeads()"
                style="margin-top: -1.5px !important;"
              >
              <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="plus-circle" size="14" /> &nbsp;
              Assign Leads</span>
              </a-button>
              </a-form-item>
              </a-col>
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    <a-row :gutter="15" style="padding: 10px">
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    Leads List
    </sdHeading>

    <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
            </a-row>
      <template v-if="selectedoption == 'Active'">
       
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 50,
                    total: TableData.length,
                    showSizeChanger: true,
                    pageSizeOptions: ['50', '100', '200','500','1000'],
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'AssignForm',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var userData = ref();
    var industryData = ref();
    var dispIndustry = ref(true);
    var dispStatus = ref(false);
    var dispFromUser = ref(false);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    const selectedChange = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.AssignData.data = [];
    var AssignData1= [];
    onMounted(() => {
    getdata();
    loaddData();
    }); 
    const formState = reactive({
      type: "Select Type",
      center: "Select Center",
      fromUser: "Select From User",
      toUser: "Select To User",
      industry: "Select Industry",
      status: "Select Current Lead Status",
      interest: "Select Interest",
    });
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    const handleSearch = (values) => {
  const substring = addFormState.search_text.toLowerCase();
  const leadsData = AssignData1.data;
  
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.Name.toLowerCase().includes(substring) || 
    data.Email_Address.toLowerCase().includes(substring)
  );
  
  // Update the state with filtered data
  state.AssignData.data = filteredData;
};
    const rules = {
      type: [
        {
          required: true,
          message: "Please select type first",
          trigger: "blur",
        },
      ],
      center: [
        {
          required: true,
          message: "Please select center first",
          trigger: "blur",
        },
      ],
      
      toUser: [
        {
          required: true,
          message: "Please select to User first!",
          trigger: "blur",
        },
      ],
      industry: [
        {
          required: true,
          message: "Please select industry first!",
          trigger: "blur",
        },
      ],
      };
    const handleOk = (values) => {
      loadData();
    };
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
const onChange=(change)=> {
     selectedChange.value=change;
     if(selectedChange.value=="assign"){
     dispIndustry.value=true;
     dispFromUser.value=false;
     dispStatus.value=false;
     }
     else{
      dispIndustry.value=true;
      dispStatus.value=true;
      dispFromUser.value=true;
     }
    }
const getdata = () => {
      loading.value = true;
      dispatch("getAssignFieldsData").then(
        (response) => {
          if (response.status == 200) {
            branchData.value = response.data[0];
            console.log(branchData.value);
            userData.value = response.data[1];
            loading.value = false;
          } else {
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
         
        }
      );
      
    };
    const assignLeads = () => {
        let api_data = [];
        api_data['selectedid'] = selectedid;
        api_data['type'] = formState.type;
        api_data['center'] = formState.center;
        api_data['fromUser'] = formState.fromUser;
        api_data['toUser'] = formState.toUser;
        api_data['industry'] = formState.industry;
        api_data['status'] = formState.status;
        api_data['interest'] = formState.interest;
        loading.value = true;
        dispatch("assignLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Assigned.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                Notification["error"]({
                message: "Error!",
                description: "Error Assigning Leads",
            });
          } 
          
        );
    }
    const loaddData = () => {
        loading.value = true;
        dispatch("manualFieldsData").then(
            (response) => {
                if (response.status == 200) {
                    industryData.value = response.data[0];
                    
                    
                } else {
                loading.value = false;
                       }
            },
            (error) => {
                loading.value = false;
            } 
          
        );
    }
    const loadData = () => {
        let api_data = [];
        api_data['type'] = formState.type;
        api_data['center'] = formState.center;
        api_data['fromUser'] = formState.fromUser;
        api_data['toUser'] = formState.toUser;
        api_data['industry'] = formState.industry;
        api_data['status'] = formState.status;
        api_data['interest'] = formState.interest;
        loading.value = true;
        dispatch("getloadLeadsdata",api_data).then(
            (response) => {
                if (response.status == 200) {
                    state.AssignData.data = response.data;
                    AssignData1.data = response.data;
                    //localStorage.setItem("search_data1",JSON.stringify(response.data));
                    console.log(response.data);
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.AssignData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
    }
    const TableColumns = [
    
      {
        title: "Company Name",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "E-mail",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Center",
        dataIndex: "branch_code",
        key: "branch_code",
        sorter: (a, b) => a.branch_code.length - b.branch_code.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "UserId",
        dataIndex: "user_id",
        key: "user_id",
        sorter: (a, b) => a.user_id.length - b.user_id.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Action",
        dataIndex: "Action",
        key: "Action",
        sorter: (a, b) => a.Action.length - b.Action.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Current Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      
    ];
   
     let TableData = computed(() =>
        state.AssignData.data.map((unreconcile) => {
          const {
            id,
            Company,
            Name,
            interest,
            user_id,
            current_status,
            Action,
            branch_code,
            Email_Address,
            } = unreconcile;
          return {
            key: id,
            id: id,
            Company: Company,
            Name:Name,
            interest: interest,
            user_id: user_id,
            current_status: current_status,
            Action: Action,
            branch_code: branch_code,
            Email_Address: Email_Address,
            } 
            
        }
        )
    );
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      userData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      loadData,
      formState,
      rules,
      assignLeads,
      addFormState,
      handleSearch,
      AssignData1,
      industryData,
      loaddData,
      dispIndustry,
      dispStatus,
      dispFromUser,
      onChange,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>