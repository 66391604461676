<template>
   <!--<sdPageHeader>
      <template #title>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Data
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
       </template>
   </sdPageHeader>-->
  <Main>
    <sdCards headless>
    <template v-if="selectedoption == 'Active'">
       <a-row :gutter="15" style="padding: 10px">
       <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Data
          </sdHeading>
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    
                    <a-form
                      name="contact"
                      :rules="rules"
                      :model="addFormState"
                      :layout="addFormState.layout"
                      style="
                        margin-top: 20px;
                        margin-right: 10px;
                      "
                    >
                      <a-row  >
                        <a-col>
                          <a-form-item name="search_text">
                            <a-input
                              v-model:value="addFormState.search_text"
                              placeholder="Search Here...."
                              @change="handleSearch"
                            />
                          </a-form-item>
                        </a-col>
                      </a-row>
                    </a-form>
                    
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row>
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'CentersForm',
  components: {
    Main,
    CardToolbox,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var linedata = [];
    var selectedoption = ref('Active');
    const { dispatch, state } = useStore();
    var supplierdrop = ref();
    var contact_id = ref(null);
    const route = useRoute();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    const orgdata = ref(JSON.parse(localStorage.getItem("syncdata")));
    onMounted(() => {
    getdata(route.params.id);
    }); 
    
    state.LeadsData.data = [];
   var LeadsData1 = [];
    const addFormState = reactive({
      search_text: "",
      layout: "vertical",
    });
    const handleSearch = (values) => {
      const substring = addFormState.search_text.toLowerCase();
  //const leadsData = JSON.parse(localStorage.getItem("search_data1"));
  const leadsData =LeadsData1.data;
  // Filter leadsData based on search criteria
  const filteredData = leadsData.filter(data => 
    data.Name.toLowerCase().includes(substring) || 
    data.Email_Address.toLowerCase().includes(substring) || 
    data.next_followup.toLowerCase().includes(substring)  ||
    data.contact_type.toLowerCase().includes(substring)

  );
  
  // Update the state with filtered data
  state.LeadsData.data = filteredData;
    };
const getdata = (id) => {
      loading.value = true;
      let api_data = [];
      api_data['ids'] = id;
      dispatch("getLeadsDataIndex",api_data).then(
        (response) => {
          if (response.status == 200) {
            state.LeadsData.data = response.data;
            LeadsData1.data = response.data;
            //localStorage.setItem("search_data",JSON.stringify(response.data));
            loading.value = false;
          } else {
            state.LeadsData.data = [];
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          loading.value = false;
          state.LeadsData.data = [];
        }
      );
    };
    const openurl = (id) => {
      let url = '/index/';
      window.open(
          url + id +'/0',
          "_self",
      ); 
      
    };
    
    const updateComplete = (id) => {
    let api_data = [];
      api_data['ids'] = id;
      dispatch("getLeadsCompleteStatus",api_data).then(
        (response) => {
          if (response.status == 200) {
            Notification["success"]({
                    message: "Succsess!",
                    description: "Task Completed Successfully.",
            });
          } else {
            Notification["error"]({
                message: "Error!",
                description: "Error Completing Task.",
            });
          }
        },
        (error) => {
          Notification["error"]({
                message: "Error!",
                description: "Error Completing Task.",
            });
        }
      );

    };
    const TableColumns = [
     {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Name",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
        width: "10%",
      },
      {
        title: "Call Date",
        dataIndex: "next_followup",
        key: "next_followup",
        sorter: (a, b) => new Date(a.next_followup) - new Date(b.next_followup),
        sortDirections: ["descend", "ascend"],
      },
      
      {
        title: "Time",
        dataIndex: "schedule_time",
        key: "schedule_time",
        sorter: (a, b) => a.schedule_time.length - b.schedule_time.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Call Type",
        dataIndex: "schedule_type",
        key: "schedule_type",
        sorter: (a, b) => a.schedule_type.length - b.schedule_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Type",
        dataIndex: "contact_type",
        key: "contact_type",
        sorter: (a, b) => a.contact_type.length - b.contact_type.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Cell Phone",
        dataIndex: "Mobile",
        key: "Mobile",
        sorter: (a, b) => a.Mobile.length - b.Mobile.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Designation",
        dataIndex: "Job_Title",
        key: "Job_Title",
        sorter: (a, b) => a.Job_Title.length - b.Job_Title.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Probability",
        dataIndex: "Probability",
        key: "Probability",
        sorter: (a, b) => a.Probability.length - b.Probability.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Completed",
        dataIndex: "complete",
        key: "complete",
        sorter: (a, b) => a.complete.length - b.complete.length,
        sortDirections: ["descend", "ascend"],
      },
    ];
  let TableData = computed(() =>
        state.LeadsData.data.map((unreconcile) => {
          const {
            id,
            lead_id,
            Company,
            Name,
            interest,
            user_id,
            Probability,
            Job_Title,
            phone,
            Mobile,
            Email_Address,
            current_status,
            schedule_type,
            schedule_date,
            schedule_time,
            next_followup,
            Action,
            contact_type,
            } = unreconcile;
          return {
            key: id,
            id: id,
            lead_id:lead_id,
            Probability: Probability,
            Job_Title:Job_Title,
            phone:phone,
            Mobile:Mobile,
            Email_Address:Email_Address,
            next_followup:next_followup,
            Company: (
            <div class="table-actions">
              <>
                <a style="text-align: left !important;"onClick={() => openurl(lead_id)} to="#">
                  <span>
                    {Company}
                    </span>
                </a>
              </>
            </div>
          ),
          complete: (
            <div class="table-actions">
              <>
                <a onClick={() => updateComplete(id)} to="#">
                  <span style="display: inline-flex !important; align-items: center !important;">
                    <sdFeatherIcons
                      type="trash-2"
                      size="22"
                      color="#1890ff"
                    />{" "}
                    &nbsp;Dispose
                  </span>
                </a>
              </>
            </div>
          ),
            Name:Name,
            interest: interest,
            user_id: user_id,
            current_status: current_status,
            Action: Action,
            schedule_type: schedule_type,
            schedule_date: schedule_date,
            schedule_time: schedule_time,
            contact_type: contact_type,
            } 
        }
        )
    );
  return {
      loading,
      TableColumns,
      TableData,
      selectcount,
      checkselected,
      selectedoption,
      getdata,
      openurl,
      supplierdrop,
      orgdata,
      route,
      addFormState,
      handleSearch,
      LeadsData1,
      updateComplete,
    };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
</style>