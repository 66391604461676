<template>

   <sdPageHeader>
      <template #title>
      <a-row :gutter="30">
        <a-col>
        <div class="product-single-description">
          <sdHeading class="product-single-title" as="h1" style="margin: 15px">
           Leads Individual Status Report
          </sdHeading>
          <span style="margin: 0px; font-size: 16px; font-weight: 400">
          </span>
        </div>
        </a-col>
        </a-row>
        <ProductCard
    class="list-view"
    :style="{ width: '100%', marginBottom: '10px' }"
    >
    <div class="product-list" style="padding: 10px;background-color:#ffffff;">
    
        <!--<BasicFormWrapper>-->
            <a-form
              name="contact"
              :rules="rules"
              :model="formState"
              @submit="handleOk"
              :layout="formState.layout"
            >
            <a-row :gutter="20">
            <a-col :md="5">
              <a-form-item name="center">
                <a-select
                  v-model:value="formState.center"
                  style="width: 100%"
                  showSearch
                >
                <a-select-option
                  v-for="item in branchData"
                  v-bind:key="item.branch_code"
                  :value="item.branch_code"
                >
                  {{ item.branch_code }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="5">
              <a-form-item name="status">
                <a-select
                v-model:value="formState.status"
                style="width: 100%"
                showSearch
                >
                <a-select-option value="Target Assignment">Fresh Target</a-select-option>
                <a-select-option value="Opportunity Assignment">Fresh Opportunity</a-select-option>
                <a-select-option value="Target In-Progress">Assigned Target</a-select-option>
                <a-select-option value="Opportunity In-Progress">Assigned Opportunity</a-select-option>
                <a-select-option value="Dead">Dead Lead</a-select-option>
                <a-select-option value="Lead In-Progress"> Lead In-Progress</a-select-option>
                <a-select-option value="Proposal">Proposal Review</a-select-option>
                <a-select-option value="Negotiation Review">Negotiation Review</a-select-option>
                <a-select-option value="Closed Lost"> Closed Lost</a-select-option>
                <a-select-option value="Closed Won">Closed Won</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col :md="4">
              <a-form-item name="User">
                <a-select
                  v-model:value="formState.user"
                  style="width: 100%"
                  showSearch
                  placeholder="Select User"
                >
                  <a-select-option
                  v-for="item in userData"
                  v-bind:key="item.user_id"
                  :value="item.user_id"
                  >
                  {{ item.user_id }}
                  </a-select-option>
                </a-select>
              </a-form-item>
             </a-col>
            <a-col :md="5">
            <a-form-item name="condition">
             <a-range-picker
                  style="padding-top: 10px !important"
                  @change="DateSelect"
                  :defaultValue="selectrange"
                />   
            </a-form-item>
            </a-col>
            
            <a-col :md="5">
            <a-form-item>
              <a-button 
                htmlType="submit"
                size="default"
                type="primary"
                key="submit"
                class="btn-add_new"
                style="margin-top: -1.5px !important;"
              >
               <span style="display: inline-flex !important;
              align-items: center !important;">
              <sdFeatherIcons type="arrow-down-circle" size="14" />  &nbsp; 
              Get Report</span>
              </a-button>
             </a-form-item>
              </a-col>
             
              </a-row>
            </a-form>

          <!--</BasicFormWrapper>-->
          
          </div>
          </ProductCard>
      </template>
     
    </sdPageHeader>
     
  <Main>
    <sdCards headless>
    
    <sdHeading class="product-single-title" as="h3" style="margin: 10px">
    List of Leads
    </sdHeading>
      <template v-if="selectedoption == 'Active'">
      <a-row :gutter="15" style="padding: 10px">
            
            <a-col :xs="24">
              <TopToolBox>
                <div class="product-single-description">
                  <div
                    class="
                      product-single-title
                      page-header-actions
                      product-single-description
                    "
                    style="float: right"
                  >
                    <a-button
                      class="btn-signin"
                      size="small"
                      type="default"
                      v-if="checkselected"
                      @click="bulkIgnore('B')"
                    >
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="x-circle" size="14" /> &nbsp;
                        Dismiss</span
                      >
                    </a-button>
                    
                    <a-button class="btn-signin" size="small" @click="exportToCSV()">
                      <span
                        style="
                          display: inline-flex !important;
                          align-items: center !important;
                        "
                      >
                        <sdFeatherIcons type="download" size="14" /> &nbsp;
                        Export</span
                      >
                    </a-button>
                  </div>
                  <span style="margin: 0px; font-size: 16px; font-weight: 400">
                    <br />
                  </span>
                </div>
              </TopToolBox>
            </a-col>
          </a-row> 
        <a-row :gutter="15">
          <a-col :md="24">
             <TableWrapper class="table-responsive">
              <a-table
                class="ant-table-striped"
                thead-class="greenColor"
                :data-source="TableData"
                :rowSelection="rowSelection"
                :columns="TableColumns"
                :row-class-name="
                  (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
                "
                :pagination="{
                    defaultPageSize: 10,
                    total: TableData.length,
                    showTotal: (total, range) =>
                      `${range[0]}-${range[1]} of ${total} items`,
                }"
              >
              </a-table>
            </TableWrapper>
          </a-col>
        </a-row>
      </template>
      
    </sdCards>
  </Main>
</template> 
<script>
import { ContactPageheaderStyle, Action, UserTableStyleWrapper ,ProjectSorting,TopToolBox,} from '../../style';
import { defineComponent } from 'vue';
import { Main, CardToolbox, TableWrapper,BasicFormWrapper } from '../../../styled';
import { dispatch, useStore } from 'vuex';
import { ProductCard } from "./style";
import { defineAsyncComponent, computed, reactive, ref, onMounted, watch } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { message, Notification, } from 'ant-design-vue'; 
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
export default defineComponent({
  name: 'SelectStatusReport',
  components: {
    Main,
    CardToolbox,
    BasicFormWrapper,
    UserTableStyleWrapper,
    TableWrapper,ProjectSorting,TopToolBox,ProductCard,
  },
  setup() {
    var loading = ref(true);
    var branchData = ref();
    var userData = ref();
    var checkselected = ref(false);
    var selectcount = ref(null);
    var selectedid = ref(null);
    var selectedoption = ref('Active');
    var selectrange = ref(null);
    const { dispatch, state } = useStore();
    const API_ENDPOINT = process.env.VUE_APP_API_ENDPOINT; 
    state.StatusData.data = [];
    onMounted(() => {
    getdata();
    }); 
    const formState = reactive({
      center: "Select Branch",
      status: "Select Leads Status",
      user: "Select User"
      });
    const rules = {
      center: [
        {
          required: true,
          message: "Please select criteria first",
          trigger: "blur",
        },
      ],
      };
    const DateSelect = (date, dateString) => {
      selectrange.value = dateString;
    };
    const handleOk = (values) => {
      statusData();
    };
    const openurl = (id) => {
      let url = '/index/';
      window.open(
          url + id +'/0',
          "_self",
      ); 
      
    };
    const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
        selectedRowKeys.value = selectedRowKeys;
        selectedRows.value = selectedRows; 
        if (selectedRowKeys.value.length == 0) {
          checkselected.value = false;
        } else {
          checkselected.value = true;
          selectedid = selectedRowKeys.value;
          selectcount.value = selectedRowKeys.value.length + ' Rows Selected';
        }
      },
     
    };
  const getdata = () => {
      loading.value = true;
      dispatch("getAssignFieldsData").then(
        (response) => {
          if (response.status == 200) {
            branchData.value = response.data[0];
            userData.value = response.data[1];
            loading.value = false;
          } else {
            loading.value = false;
            //onCancel();
          }
        },
        (error) => {
          console.log(error);
          loading.value = false;
         }
      );
      
    };
    const statusData = () => {
      var range = selectrange.value.toString().split(",");
     let startdate = new Date(range[0]);
      let enddate = new Date(range[1]);
      startdate = startdate.toLocaleDateString("en-CA");
      enddate = enddate.toLocaleDateString("en-CA");
        let api_data = [];
        api_data['center'] = formState.center;
        api_data['status'] = formState.status;
        api_data['selected_user'] = formState.user;
        api_data['startdate'] = startdate;
        api_data['enddate'] = enddate;
        
        loading.value = true;
        dispatch("selectstatusLeads",api_data).then(
            (response) => {
                if (response.status == 200) {
                    state.StatusData.data = response.data;
                    Notification["success"]({
                    message: "Succsess!",
                    description: "Leads Loaded.",
                    });
                    loading.value = false;
                } else {
                    loading.value = false;
                    //onCancel();
                }
            },
            (error) => {
                loading.value = false;
                state.StatusData.data = [];
                Notification["error"]({
                message: "Error!",
                description: "Error Loading Leads",
            });
          } 
          
        );
    }
    const TableColumns = [
      {
        title: "Company",
        dataIndex: "Company",
        key: "Company",
        sorter: (a, b) => a.Company.length - b.Company.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Current User",
        dataIndex: "Assign_To",
        key: "Assign_To",
        sorter: (a, b) => a.Assign_To.length - b.Assign_To.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Contact Person",
        dataIndex: "Name",
        key: "Name",
        sorter: (a, b) => a.Name.length - b.Name.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Phone",
        dataIndex: "phone",
        key: "phone",
        sorter: (a, b) => a.phone.length - b.phone.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Website",
        dataIndex: "Website",
        key: "Website",
        sorter: (a, b) => a.Website.length - b.v.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Email",
        dataIndex: "Email_Address",
        key: "Email_Address",
        sorter: (a, b) => a.Email_Address.length - b.Email_Address.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Job Title",
        dataIndex: "Job_Title",
        key: "Job_Title",
        sorter: (a, b) => a.Job_Title.length - b.Job_Title.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Opportunity",
        dataIndex: "opportunity_amt",
        key: "opportunity_amt",
        sorter: (a, b) => a.opportunity_amt.length - b.opportunity_amt.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Turnover",
        dataIndex: "turn_over",
        key: "turn_over",
        sorter: (a, b) => a.turn_over.length - b.turn_over.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Probability",
        dataIndex: "Probability",
        key: "Probability",
        sorter: (a, b) => a.Probability.length - b.Probability.length,
        sortDirections: ["descend", "ascend"],
      },
      
      {
        title: "SLA Signed Date",
        dataIndex: "sla",
        key: "sla",
        sorter: (a, b) => a.sla.length - b.sla.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Go live Date",
        dataIndex: "live",
        key: "live",
        sorter: (a, b) => a.live.length - b.live.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Industry",
        dataIndex: "Industry",
        key: "Industry",
        sorter: (a, b) => a.Industry.length - b.Industry.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Lead Source",
        dataIndex: "Lead_Source",
        key: "Lead_Source",
        sorter: (a, b) => a.Lead_Source.length - b.Lead_Source.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Next Follow-up",
        dataIndex: "next_followup",
        key: "next_followup",
        sorter: (a, b) => a.next_followup.length - b.next_followup.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Comment",
        dataIndex: "Text",
        key: "Text",
        sorter: (a, b) => a.Text.length - b.Text.length,
        sortDirections: ["descend", "ascend"],
      },
      {
        title: "Current Status",
        dataIndex: "current_status",
        key: "current_status",
        sorter: (a, b) => a.current_status.length - b.current_status.length,
        sortDirections: ["descend", "ascend"],
      },
      
      
    ];
   
     let TableData = computed(() =>
        state.StatusData.data.map((unreconcile) => {
          const {
            id,
            ref_id,
            Company,
            Name,
            phone,
            Website,
            Email_Address,
            current_status,
            user_id,
            user,
            Assign_To,
            Action,
            turn_over,
            Probability,
            Job_Title,
            Industry,
            Lead_Source,
            Country,
            total_employees,
            opportunity_amt,
            next_followup,
            sla,
            live,
            Text,
            } = unreconcile;
          return {
            key: id,
            id: id,
            ref_id:ref_id,
            Company: (
            <div class="table-actions">
              <>
                <a style="text-align: left !important;"onClick={() => openurl(ref_id)} to="#">
                  <span>
                    {Company}
                    </span>
                </a>
              </>
            </div>
          ),
            Name:Name,
            phone:phone,
            Website:Website,
            Email_Address:Email_Address,
            current_status: current_status,
            user_id: user_id,
            user: user,
            Assign_To: Assign_To,
            Action: Action,
            turn_over: turn_over,
            Probability: Probability,
            Job_Title: Job_Title,
            Industry: Industry,
            Lead_Source: Lead_Source,
            Country: Country,
            total_employees: total_employees,
            opportunity_amt: opportunity_amt,
            next_followup: next_followup,
            sla: sla,
            live: live,
            Text: Text,
            } 
            
        }
        )
    );

    const exportToCSV = () => {
      const inputData = state.StatusData.data.map((unreconcile) => {
        const {
          id,
            Company,
            Name,
            phone,
            Website,
            Email_Address,
            current_status,
            user_id,
            user,
            Assign_To,
            Action,
            turn_over,
            Probability,
            Job_Title,
            Industry,
            Lead_Source,
            Country,
            total_employees,
            opportunity_amt,
            next_followup,
            sla,
            live,
            Text,
        } = unreconcile;
        return {
            id: id,
            "Assigned User": Assign_To,
            Company: Company,
            Name:Name,
            phone:phone,
            Website:Website,
            Email_Address:Email_Address,
            "SLA Signed Date": sla,
            "Go Live Date": live,
            "Job Title": Job_Title,
            "Opportunity Amt": opportunity_amt,
            "Turn-over": turn_over,
            Probability: Probability,
            Industry: Industry,
            "Lead Source": Lead_Source,
            "Next Follow-up": next_followup,
            "Action By": user,
            "Comment": Text,
            "Current Status": current_status,
            
          };
      });

      var linedata = [];

      
      var range = selectrange.value.toString().split(",");
      const fileName ="Leads Individual Status Report " +range[0]+"-"+range[1];
      const fileType =
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
      const fileExtension = ".xlsx";

      const ws = XLSX.utils.json_to_sheet(inputData);
      const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
      const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
      const data = new Blob([excelBuffer], { type: fileType });
      FileSaver.saveAs(data, fileName + fileExtension);

      Notification["success"]({
        message: "Success!",
        description: "Downloaded.",
      });
    };
    return {
      loading,
      TableColumns,
      TableData,
      branchData,
      userData,
      rowSelection,
      selectcount,
      checkselected,
      selectedoption,
      handleOk,
      exportToCSV,
      formState,
      selectrange,
      DateSelect,
      rules,
      openurl,
     };
  },
});
</script> 
<style scoped>
.ant-table-striped :deep(.table-striped) td th{
  background-color: #447316;
}
th {
  //background-color: #93BF34 !important;
}
.ant-btn-primary {
  background-color: #5C69B3 !important;
  border: 1px solid #5C69B3 !important;
}
.supp-head div{
        width: 250px;
}

.ant-col .ant-select {
  margin-top: 4px;
}
.ant-col button{
  margin-top: 5px;
}
table thead tr th:last-child {
    text-align: left !important;
}
.ant-page-header-heading {
  display: block !important;

}
</style>